import React from 'react';
import styled from '@emotion/styled';
import {colors, fonts, fontWeights} from "@utils/base";

const Paragraph = ({children, className}) => {
  className = className || '';
  const P = styled.p`
    font-family: ${fonts.tektonPro};
    font-weight: ${fontWeights.normal};
    color: ${colors.text};
  `;

  return <P className={className}>{children}</P>;
}

export default Paragraph;
