/* @jsx jsx */
import React from 'react';
import {css, jsx} from '@emotion/react';
import Heading3 from "@atoms/headings/heading3/heading3";
import Image from "@atoms/image";
import Paragraph from "@atoms/paragraph";
import {colors, transitions, unitConverter} from "@utils/base";
import {Link} from "gatsby";

const Video = ({id, pilot, slug, title, thumbnail, className, avatarSize = 30}) => {
  const videoCss = css`
    position: relative;
  `;

  const videoImageCss = css`

    min-height: 207px;

    &::before,
    &::after {
      content: '';
      position: absolute;
      background-color: black;
      z-index: 2;
      width: 100%;
      padding-bottom: 9%;
    }

    &::before {
      top: 0;
    }

    &::after {
      bottom: 0;
    }
  `;

  const videoTitleCss = css`
    position: relative;
    &::before {
      position: absolute;
      left: 0;
      top: 0;
      height: ${unitConverter('25px')};
    }

    a {
      color: inherit;
      text-decoration: none;
    }
  `;

  const pilotInfoWrapperCss = css`
    display: flex;
    flex-wrap: wrap;
    margin-top: ${unitConverter('10px')};
  `;

  const avatarCss = css`
    position: relative;
    top: ${unitConverter('5px')};
    width: ${unitConverter(`${avatarSize}px`)};
    height: ${unitConverter(`${avatarSize}px`)};
  `;

  const pilotInfoCss = css`
    width: 100%;
    max-width: calc(100% - ${unitConverter(`${avatarSize}px`)});
    padding-left: ${unitConverter('10px')};
  `;

  const pilotCss = css`
    color: ${colors.lightGray};

    a {
      color: inherit;
      text-decoration: none;
      transition: all ${transitions.fast} ease-in-out;

      &:hover {
        color: ${colors.lightGrayHover};
      }
    }
  `;

  return (
    <div css={videoCss} className={className}>
      <Link to={`/${pilot.handle}/${slug.current}/`}>
        <div className="main-image-wrapper">
          <Image src={thumbnail} alt={title} css={videoImageCss} />
        </div>
      </Link>
      <div css={pilotInfoWrapperCss}>
        {pilot.avatar && (
          <Link to={`/${pilot.handle}/`}>
            <Image src={pilot.avatar} alt={pilot.handle} maxWidth={50} css={avatarCss} />
          </Link>
        )}
        <div css={pilotInfoCss} className="video-info">
            {title && (
              <Heading3 css={videoTitleCss}>
                <Link to={`/${pilot.handle}/${slug.current}/`}>{title}</Link>
              </Heading3>
            )}
            {pilot.handle && pilot.avatar && (
              <Paragraph css={pilotCss}>
                <Link to={`/${pilot.handle}/`}>
                  {pilot.handle}
                </Link>
              </Paragraph>
            )}
        </div>
      </div>
    </div>
  );
};

export default Video;
